* {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: none;
  box-sizing: border-box; }

body {
  zoom: 1;
  position: relative; }

html, body {
  height: 100%;
  font-family: 'Playfair Display', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #465046;
  background-repeat: no-repeat; }

input, select, textarea, button, a {
  outline: none;
  font-family: inherit;
  box-sizing: border-box; }

textarea {
  -webkit-overflow-scrolling: touch; }

#root {
  height: 100%;
  overflow-x: hidden; }

h1 {
  text-indent: -9999px;
  height: 1px; }

h2 {
  font-size: 2vw;
  line-height: 120%;
  font-weight: 300;
  margin-bottom: 20px;
  -webkit-font-smoothing: antialiased; }
  @media only screen and (max-width: 1199px) {
    h2 {
      font-size: 24px; } }

h3 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 300;
  margin-bottom: 14px;
  color: #735991;
  -webkit-font-smoothing: antialiased; }
  @media only screen and (max-width: 1199px) {
    h3 {
      font-size: 18px; } }

h4 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 10px;
  -webkit-font-smoothing: antialiased; }

a {
  color: #735991;
  text-decoration: none; }
  a img {
    outline-color: none; }
  a:hover {
    text-decoration: underline; }

p {
  font-size: 16px;
  line-height: 180%;
  font-weight: 300;
  margin-bottom: 28px; }
  @media only screen and (max-width: 1199px) {
    p {
      font-size: 16px; } }
  p b {
    color: #735991; }
  p.important {
    font-size: 18px;
    color: #735991;
    border-bottom: 1px solid #DCDDDF;
    padding-bottom: 32px;
    margin-bottom: 32px; }
  p.border {
    border-bottom: 1px solid #DCDDDF;
    padding-bottom: 32px;
    margin-bottom: 32px; }

ul {
  margin-bottom: 20px;
  padding-left: 32px; }
  ul li {
    font-size: 16px;
    line-height: 150%;
    font-weight: 300;
    margin-bottom: 10px; }

img {
  width: 100%;
  height: 100%;
  margin-bottom: 26px; }

.wrapper {
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 1199px) {
    .wrapper {
      background: rgba(255, 255, 255, 0.6); } }

.mainContainer {
  width: 60%;
  max-width: 800px;
  margin: auto;
  z-index: 100;
  padding-bottom: 48px; }
  .mainContainer img {
    margin: 0 auto;
    display: block;
    padding-bottom: 20px; }
  @media only screen and (max-width: 1199px) {
    .mainContainer {
      margin: 0 auto;
      width: 90%; } }
  .mainContainer.gallery {
    max-width: 1024px;
    width: 100%; }
    @media only screen and (max-width: 1199px) {
      .mainContainer.gallery {
        max-width: inherit;
        width: inherit; } }

label {
  display: block; }

input {
  -webkit-appearance: none;
  -webkit-border-radius: none;
  display: block;
  padding: 8px;
  border: 1px solid #DCDDDF;
  border-radius: 4px;
  margin: 4px 0 12px 0;
  font-size: 16px; }

button {
  -webkit-appearance: none;
  -webkit-border-radius: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #735991;
  color: #fff;
  font-size: 14px; }

caption {
  width: 100%;
  display: block;
  font-size: 12px;
  text-align: left; }

.wrapper .login {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .wrapper .login form {
    width: 300px; }
    .wrapper .login form label {
      text-align: center; }
    .wrapper .login form input {
      width: 100%; }
    .wrapper .login form button {
      width: 100%; }

header {
  margin: 36px 0; }
  header .logo {
    width: 400px;
    margin: 0 auto;
    display: block;
    padding: 50px 0 12px 0; }
    @media only screen and (max-width: 500px) {
      header .logo {
        width: 90%; } }
  header .navContainer {
    display: flex;
    padding: 0 16px;
    margin: 24px 0 8px 0; }
    header .navContainer h2 {
      margin-bottom: 0;
      padding-top: 8px;
      width: 100%;
      border-bottom: 2px solid #735991;
      line-height: 100%;
      color: #735991; }
  header .showMore {
    margin-left: 16px;
    display: block; }
    header .showMore button {
      height: 44px;
      width: 44px;
      padding: 8px; }
    header .showMore img {
      margin-bottom: 0; }
  header .nav {
    display: flex; }
    @media only screen and (min-width: 500px) {
      header .nav {
        justify-content: center;
        margin-top: 42px;
        opacity: 1; }
        header .nav a {
          font-size: 18px;
          display: inline-block; }
          header .nav a:hover::after {
            content: '';
            display: block;
            margin-top: 4px;
            height: 2px;
            background: #735991;
            transition: width .3s;
            width: 100%; }
          header .nav a::after {
            content: '';
            display: block;
            margin-top: 4px;
            height: 2px;
            background: #735991;
            transition: width .3s;
            width: 0; }
          header .nav a.active::after {
            content: '';
            display: block;
            margin-top: 4px;
            height: 2px;
            background: #735991;
            transition: width .3s;
            width: 100%; }
          header .nav a:not(:last-child) {
            margin-right: 36px; }
          header .nav a:hover {
            text-decoration: none; } }
    @media only screen and (max-width: 500px) {
      header .nav {
        padding: 0 16px;
        flex-direction: column;
        width: 100%; }
        header .nav a {
          font-size: 20px;
          margin-bottom: 12px; }
        header .nav .active {
          display: none; } }
  header p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 8px;
    -webkit-font-smoothing: antialiased;
    text-align: center; }
    header p span:first-child {
      border-right: 1px solid;
      padding-right: 16px;
      margin-right: 16px; }

.SubHeader {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 32px; }
  @media only screen and (max-width: 1199px) {
    .SubHeader {
      width: 90%; } }
  .SubHeader img {
    margin: 0 auto;
    display: block; }
    .SubHeader img.hero {
      margin-bottom: 32px; }
      @media only screen and (max-width: 1199px) {
        .SubHeader img.hero {
          margin-bottom: 0; } }
  .SubHeader.home img.title {
    width: 144px;
    height: 49px; }
    @media only screen and (max-width: 1199px) {
      .SubHeader.home img.title {
        display: none; } }
  .SubHeader.details img.title {
    width: 248px;
    height: 73px; }
    @media only screen and (max-width: 1199px) {
      .SubHeader.details img.title {
        display: none; } }
  .SubHeader.rsvp img.title {
    width: 112px;
    height: 50px; }
    @media only screen and (max-width: 1199px) {
      .SubHeader.rsvp img.title {
        display: none; } }
  .SubHeader.registry img.title {
    width: 122px;
    height: 68px; }
    @media only screen and (max-width: 1199px) {
      .SubHeader.registry img.title {
        display: none; } }
  .SubHeader.gallery img.title {
    width: 110px;
    height: 68px; }
    @media only screen and (max-width: 1199px) {
      .SubHeader.gallery img.title {
        display: none; } }

.gallery.SubHeader img.hero {
  display: none; }

.mainContainer .home img {
  width: 75%; }
  @media only screen and (max-width: 500px) {
    .mainContainer .home img {
      width: 100%; } }

.formContainer legend {
  margin-bottom: 8px; }

.formContainer input {
  width: 100%;
  margin: 4px 0; }

.formContainer input.submit {
  width: inherit;
  padding: 12px 28px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #735991;
  color: #fff;
  font-size: 14px;
  border: none; }
  .formContainer input.submit:hover {
    opacity: .8; }

.question {
  width: 48%;
  margin-bottom: 20px; }
  @media only screen and (max-width: 500px) {
    .question {
      width: 100%; } }

.form-row {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 500px) {
    .form-row {
      display: block; } }

.visual-picker {
  display: flex;
  position: relative;
  flex-direction: column;
  border: 0;
  border-radius: 0;
  text-align: center;
  cursor: pointer;
  width: 48%;
  margin-bottom: 20px; }
  @media only screen and (max-width: 500px) {
    .visual-picker {
      width: 100%; } }
  .visual-picker input {
    width: 1px;
    height: 1px;
    border: 0;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute; }

.visual-picker-item {
  background: white;
  border: 1px solid #dddbda;
  border-radius: 0.25rem;
  padding: 1rem;
  display: block;
  width: 100%;
  text-align: left; }
  .visual-picker-item:hover {
    border: 1px #735991 solid;
    box-shadow: 0 0 0 1px #735991 inset; }

.visual-picker-header {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  display: block; }

.visual-picker-text {
  margin-top: 10px; }

.visual-picker input:checked + label .visual-picker-item {
  border: 1px #735991 solid;
  box-shadow: 0 0 0 1px #735991 inset;
  background-color: #735991;
  color: #fff; }

.form-success {
  background-color: #FBF3FF;
  transition: all .7s;
  text-align: center;
  padding: 20px;
  margin-top: 16px;
  border-radius: 12px;
  cursor: default; }
  .form-success p {
    margin-bottom: 0; }

.close-button {
  position: relative; }
  .close-button button {
    position: absolute;
    background-color: transparent;
    padding: 8px;
    right: -4px;
    top: -4px; }
  .close-button svg {
    fill: #735991;
    width: 20px;
    height: 20px; }

.visual-error {
  margin-top: -12px;
  margin-bottom: 20px; }

.gallery p {
  text-align: center; }

.gallery-container {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-flow: row dense;
  margin-bottom: 24px; }

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding-bottom: 0; }

.gallery-load-more {
  display: flex;
  width: 100%;
  justify-content: space-around; }
  .gallery-load-more button {
    padding: 20px 32px; }

footer {
  margin: 0 auto;
  background-color: #F3F5E9;
  padding: 42px 0; }
  footer .container {
    width: 60%;
    max-width: 800px;
    margin: auto; }
    footer .container p {
      text-align: center;
      margin-bottom: 0; }

img.branchOne {
  position: absolute;
  top: -304px;
  height: 660px;
  width: auto;
  left: 220px;
  transform: rotate(-108deg);
  z-index: -1;
  margin-bottom: 0; }
  @media only screen and (max-width: 1199px) {
    img.branchOne {
      transform: rotate(62deg);
      left: 115px;
      height: 680px;
      top: -243px; } }

img.branchTwo {
  position: absolute;
  top: -305px;
  height: 660px;
  width: auto;
  right: 220px;
  transform: rotate(108deg) scaleX(-1);
  z-index: -1;
  margin-bottom: 0; }
  @media only screen and (max-width: 1199px) {
    img.branchTwo {
      display: none; } }

button:disabled {
  background-color: #EFEFEF;
  color: #4A4A4A; }

@keyframes fadeInImg {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.img-loading {
  opacity: 0;
  width: 100%;
  height: auto; }

.img-loaded {
  animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
  position: relative;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 0.7s;
  animation-delay: 0.1s; }
